import React from "react"
const H2 = ({ children, display, className }) => {
  return (
    <h2
      className={`font-extrabold leading-relaxed ${
        display
          ? "text-3xl sm:text-4xl md:text-5xl"
          : "text-xl sm:text-2xl md:text-3xl"
      } ${className}`}
    >
      {children}
    </h2>
  )
}

export default H2
