import React from "react"
import Layout from "../../components/layouts/Default"
import Container from "../../components/common/Container"
import H2 from "../../components/typography/H2"
import P from "../../components/typography/P"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Newsletter Anmeldung/Abmeldung fehlgeschlagen"
      desc=""
    >
      <Container>
        <div className="max-w-2xl mx-auto">
          <H2 className="mb-4">
            Newsletter Anmeldung/Abmeldung fehlgeschlagen
          </H2>
          <P className="mb-2">
            Ooops! Da ist etwas schief gelaufen. Bitte versuche es später noch
            einmal oder kontaktiere uns direkt.
          </P>
        </div>
      </Container>
    </Layout>
  )
}
